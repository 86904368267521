import React from 'react';
import PropTypes from 'prop-types';

import { Card, PolycardContext } from '@polycard/card';
import { CarouselSnappedSlide, CarouselSnapped } from '@andes/carousel-snapped';
import { CarouselFree, CarouselFreeSlide } from '@andes/carousel-free';

import { WITH_FREE_CAROUSEL } from '../../utils/constants/shop/types';
import { CAROUSEL_CONFIG_BY_DEVICE, FORMATS_SEPARATOR } from './utils';
import { DEVICES } from '../../utils/constants/common';

const PolycardCarousel = ({
  namespace,
  device,
  shopType,
  overrideSettings,
  polycards,
  polycardContext,
  siteId,
  trackingId,
}) => {
  const isFreeCarousel = WITH_FREE_CAROUSEL.includes(shopType) && device !== DEVICES.DESKTOP;

  const settingFreeCarousel = {
    pagination: false,
    spacing: 12,
    id: 'free-carousel',
    ...overrideSettings,
  };

  const settingCarousel = {
    ...CAROUSEL_CONFIG_BY_DEVICE.CAROUSEL[device],
    pagination: false,
    id: 'carousel',
    ...overrideSettings,
  };

  const getNewUrlFragment = urlFragment => {
    if (!urlFragment || urlFragment === '') return urlFragment;
    const hast = urlFragment.substring(0, 1);
    const decodedFraments = decodeURIComponent(urlFragment.substring(1));
    const newUrl = decodedFraments.replace(/tracking_id=\{tracking_id\}/g, `tracking_id=${trackingId}`);
    const newUrlFragment = encodeURIComponent(newUrl);
    return hast + newUrlFragment;
  };

  const getFreeCarousel = () => (
    <CarouselFree {...settingFreeCarousel}>
      {polycards.map((polycard) => (
        <CarouselFreeSlide key={`polycard-${polycard?.metadata?.id}`}>
          <Card
            polycard={
              {
                ...polycard,
                metadata: {
                  ...polycard.metadata,
                  url_fragments: getNewUrlFragment(polycard?.metadata?.url_fragments),
                },
              }
            }
          />
        </CarouselFreeSlide>
      ))}
    </CarouselFree>
  );

  const getCarousel = () => (
    <CarouselSnapped {...settingCarousel}>
      {polycards.map((polycard) => (
        <CarouselSnappedSlide key={`polycard-${polycard?.metadata?.id}`}>
          <Card
            polycard={
              {
                ...polycard,
                metadata: {
                  ...polycard.metadata,
                  url_fragments: getNewUrlFragment(polycard?.metadata?.url_fragments),
                },
              }
            }
          />
        </CarouselSnappedSlide>
      ))}
    </CarouselSnapped>
  );

  const formats = {
    thousandSeparator: FORMATS_SEPARATOR.thousand[siteId] || FORMATS_SEPARATOR.thousand.default,
    decimalSeparator: FORMATS_SEPARATOR.decimal[siteId] || FORMATS_SEPARATOR.decimal.default,
  };

  return (
    <section className={`${namespace} ui-ms-section-eshops`}>
      <PolycardContext
        contextValue={
        {
          ...polycardContext,
          type: 'grid-card',
          target: '_self',
        }
        }
        deviceType={device}
        withoutIcons
        {...formats}
      >
        {isFreeCarousel ? getFreeCarousel() : getCarousel()}
      </PolycardContext>
    </section>
  );
};

PolycardCarousel.propTypes = {
  namespace: PropTypes.string,
  device: PropTypes.string.isRequired,
  shopType: PropTypes.string.isRequired,
  overrideSettings: PropTypes.shape({}),
  polycards: PropTypes.arrayOf(PropTypes.shape({})),
  polycardContext: PropTypes.shape({}).isRequired,
  siteId: PropTypes.string.isRequired,
  trackingId: PropTypes.string,
};

PolycardCarousel.defaultProps = {
  namespace: 'ui-ms-polycard-carousel',
  overrideSettings: {},
  polycards: [],
};

export default PolycardCarousel;
