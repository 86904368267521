const { useEffect } = require('react');
const { setWebkitTitle } = require('../webkit/setTitle');

const useTitle = (initialTitle) => {
  useEffect(() => {
    const title = initialTitle || document.title;
    setWebkitTitle(title);
  }, []);
};

module.exports = {
  useTitle,
};

