import React, { useState } from 'react';
import { node } from 'prop-types';

import BottomSheetContext from './BottomSheetContext';

const BottomSheetProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState('');

  return (
    <BottomSheetContext.Provider value={{ isOpen, setIsOpen, url, setUrl }}>{children}</BottomSheetContext.Provider>
  );
};

BottomSheetProvider.propTypes = {
  children: node.isRequired,
};

export default BottomSheetProvider;
