import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from 'nordic/image';

import PlayIconVideo from '../PlayIcon';

const VideoPreview = ({
  className,
  image,
  title,
  lazyload = 'on',
  isExplorerAndV2 = false,
  onClik = () => {},
  layoutTheme,
  i18n,
}) => {
  const imageIsEmpty = image === '';
  const backgroundImage = classNames(className, {
    'ui-ms-background-without-image': imageIsEmpty,
  });

  return (
    <>
      {
        imageIsEmpty ? <div className={backgroundImage} /> : (
          <Image
            className={className}
            src={image}
            alt={title.text}
            lazyload={lazyload}
          />
        )
      }
      {!isExplorerAndV2 && <PlayIconVideo
        onImageClick={onClik}
        theme={layoutTheme}
        i18n={i18n}
      />}
    </>
  );
};

VideoPreview.propTypes = {
  className: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.shape({
    text: PropTypes.string.isRequired,
    hidden: PropTypes.bool.isRequired,
  }).isRequired,
  lazyload: PropTypes.string,
  isExplorerAndV2: PropTypes.bool,
  onClik: PropTypes.func,
  layoutTheme: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

export default VideoPreview;
