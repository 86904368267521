/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
import React from 'react';
import { Tab, Tabs } from '@andes/tabs';
import { func, number, array } from 'prop-types';

import { sendTracksTabs, getTracksTabs } from '../../utils/eshopTracks';

const CategoryTab = ({ tabs = [], selectedTab, onClick: handleClick }) => {
  const handleClickTab = (id) => {
    const tracks = getTracksTabs(tabs, selectedTab);
    const tabName = tabs[selectedTab]?.label;
    const ccpData = sendTracksTabs(tracks, tabName, selectedTab);
    if (handleClick) {
      handleClick(id, ccpData);
    }
  };

  return (
    <div className="eshop-home-nav-tabs scrollable-tabs-container">
      <Tabs selectedTab={selectedTab}>
        {tabs?.map(({ label, id }) => (
          <Tab
            key={id}
            title={label}
            onClick={() => handleClickTab(id)}
          />
        ))}
      </Tabs>
    </div>
  );
};

CategoryTab.protoTypes = {
  tabs: array.isRequired,
  selectedTab: number,
  onClick: func,
};

export default CategoryTab;
