import { MODIFIERS, regularExpressions } from './constants';

const buildRegularExpression = regularExpressionString => new RegExp(regularExpressionString);

const isVideoUrl = (url, regularExpression) => buildRegularExpression(regularExpression).test(url);

const isDailymotionVideoUrl = url => isVideoUrl(url, regularExpressions.IS_DAILYMOTION);
const isVimeoVideoUrl = url => isVideoUrl(url, regularExpressions.IS_VIMEO);
const isTwitchVideoUrl = url => isVideoUrl(url, regularExpressions.IS_TWITCH);
const isVideoLinkUrl = url => isVideoUrl(url, regularExpressions.IS_URL_GENERAL);
const isYoutubeVideoUrl = url => isVideoUrl(url, regularExpressions.IS_VIDEO_YOUTUBE);

const validateVideoUrl = url => {
  const {
    DEFAULT,
    ERROR,
  } = MODIFIERS;

  if (!url) {
    return DEFAULT;
  }

  const validVideo = isVimeoVideoUrl(url)
    || isYoutubeVideoUrl(url)
    || isDailymotionVideoUrl(url)
    || isTwitchVideoUrl(url)
    || isVideoLinkUrl(url);
  return validVideo ? DEFAULT : ERROR;
};

export {
  isYoutubeVideoUrl,
  validateVideoUrl,
};
