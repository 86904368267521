const { trackEvent } = require('./tracking');

const eshopReportTracks = ({ melidata_track: melidataTrack, type, paths }) => ({
  melidata_event: {
    type,
    path: paths?.report,
    event_data: melidataTrack?.event_data,
  },
});

const eshopShareTracks = (tracks, socialMedia) => {
  const { melidata_track: melidataTrack, type, paths } = tracks;
  return {
    melidata_event: {
      type,
      path: paths?.share,
      event_data: { ...melidataTrack.event_data, share_media: socialMedia },
    },
  };
};

const sendTracksTabs = (tracks, nameL2, positionL2) => {
  if (tracks) {
    const { melidata_track: originalMelidataTracks } = tracks;

    const melidataTracks = { ...originalMelidataTracks };

    melidataTracks.path = '/eshops/home/custom_content_page/tab';
    melidataTracks.event_data = {
      ...melidataTracks.event_data,
      node_name_l2: nameL2,
      node_position_l2: positionL2,
    };

    delete melidataTracks.event_data.node_count_l1;

    trackEvent({ melidata_event: melidataTracks });
  }
};

const getTracksCcp = (appearance) => {
  const dynamicCarousels = appearance.getComponentByName('DynamicCarousels');

  if (dynamicCarousels) {
    const { tabs } = dynamicCarousels.properties;

    if (tabs.length > 0) {
      return tabs[0].tracks;
    }
  }
  return null;
};

const getRepurchaseTracks = (appearance) => {
  const repurchase = appearance.getComponentByName('Repurchase');

  return { has_repurchase: !!repurchase };
};


const getTracksTabs = (tab, selectedTab) => {
  if (tab) {
    const selectedTabTracks = tab[selectedTab].tracks;
    const modifiedTracks = {
      ...selectedTabTracks,
      melidata_track: {
        type: selectedTabTracks.melidata_track.type,
        event_data: {
          ...selectedTabTracks.melidata_track.event_data,
          node_position_l2: selectedTab,
          node_name_l2: tab[selectedTab].label,
        },
      },
    };
    return modifiedTracks;
  }
  return null;
};

module.exports = {
  eshopReportTracks,
  eshopShareTracks,
  sendTracksTabs,
  getTracksCcp,
  getTracksTabs,
  getRepurchaseTracks,
};
