const React = require('react');
const PropTypes = require('prop-types');
const ClassNames = require('classnames');

const { useRenderContext } = require('../../../home/context');

const { THEMES_WITH_TRANSPARENT_HEADER } = require('../../../../utils/constants/themeConfig');
const { HOME } = require('../../../../utils/constants/sections');
const { isFirstComponent } = require('../../../home/components/HomeLayout/utils');

const HomeLayout = ({ section, children, shopName, osName, isWebview, isFeatureFlagNewImages }) => {
  const { appearance, isShowMenuNavigation } = useRenderContext();

  const layoutTheme = appearance.getComponentProperty('Document', 'theme');
  const themeUsesTransparency = THEMES_WITH_TRANSPARENT_HEADER.includes(layoutTheme);
  const isTransparent = true;

  const componentsToSearch = ['Slider'];
  const isFirstElement = isFirstComponent(componentsToSearch, appearance);

  const homeClass = ClassNames(
    'home',
    layoutTheme ? `home--${layoutTheme}` : null,
    {
      'home--with-transparency': isFirstElement && themeUsesTransparency && isTransparent,
      'home--with-payment': appearance.getComponentByName('PaymentMethods'),
      'home--with-flash': appearance.getComponentProperty('Header', 'flash'),
      'home--with-banner': appearance.getComponentByName('Banner'),
      'home--with-padding-bottom': !appearance.getComponentByName('ShoppingInfo'),
      'home--padding-top-content': (section !== HOME),
      [`home--os-name__${osName}`]: osName,
      [`home--os-name__${osName}--webview`]: osName && isWebview,
      'home--navigation': isShowMenuNavigation,
      'home--with-images-v2': isFeatureFlagNewImages,
    },
  );

  return (
    <div className={homeClass}>
      <h1 className="clipped">{shopName}</h1>
      { children }
    </div>
  );
};

HomeLayout.propTypes = {
  section: PropTypes.string.isRequired,
  shopName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

module.exports = HomeLayout;
