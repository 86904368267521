import React from 'react';
import PropTypes from 'prop-types';

import withLazyLoading from '../../adapters/withLazyLoading';

import { Repurchase as RepurchaseWidget } from 'eshops-components-library';
import { useRenderContext } from '../../pages/home/context';
import { DEVICES } from '../../utils/constants/common';

const namespace = 'repurchase-wrapper';

const Repurchase = ({
  title,
  link,
  deeplink,
  description,
  button_label: buttonLabel,
  variation,
  ...props
}) => {
  const { marketplaceInfo: { isWebview }, device } = useRenderContext();
  const isMobile = device === DEVICES.MOBILE;
  const showTitle = !title?.hidden && title?.text;

  return (
    <section className={namespace}>
      {showTitle && !isMobile && <h2 className="section-category__title repurchase-title">{title?.text}</h2>}
      <RepurchaseWidget
        redirectLink={isWebview ? deeplink : link}
        title={isMobile ? description?.title : ''}
        subtitle={description?.text}
        buttonLabel={buttonLabel}
        variation={variation}
        {...props}
      />
    </section>
  );
};

Repurchase.propTypes = {
  buttonLabel: PropTypes.shape({
    accessibility_text: PropTypes.string,
    text: PropTypes.string,
  }),
  deeplink: PropTypes.string,
  description: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
  }),
  link: PropTypes.string,
  title: PropTypes.shape({
    text: PropTypes.string,
    hidden: PropTypes.bool,
  }),
  variation: PropTypes.string,
};

export default withLazyLoading(Repurchase);
