import React from 'react';
import PropTypes from 'prop-types';
import PlayIconCaptive from './captive';
import PlayIconExplorer from './explorer';
import PlayIconDefault from './default';

const PLAY_ICON_BY_THEME = {
  captive: PlayIconCaptive,
  explorer: PlayIconExplorer,
};

const PlayIcon = ({ onImageClick, theme, i18n }) => {
  const PlayIconComponent = PLAY_ICON_BY_THEME[theme] || PlayIconDefault;

  return <PlayIconComponent onImageClick={onImageClick} i18n={i18n} />;
};

PlayIcon.propTypes = {
  onImageClick: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

export default PlayIcon;
