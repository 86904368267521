import React from 'react';
import { string, array } from 'prop-types';
import classNames from 'classnames';
import { useRenderContext } from '../../pages/home/context';
import withBottomSheetContext from '../../adapters/withBottomSheetContext';
import { isAllowedOrigin } from '../../pages/home/utils/utils';

const { BottomSheetContent } = require('eshops-components-library').default;

const behaviorType = {
  REDIRECT: 'redirect',
  BOTTOM_SHEET: 'bottom_sheet',
};

const behaviorTypeAction = {
  [behaviorType.REDIRECT]: () => window.MobileWebKit.navigation.pop(),
  [behaviorType.BOTTOM_SHEET]: () => window.MobileWebKit.executeNative({
    method: 'navigation_bottomsheet',
    args: {
      close: true,
      deeplink: '',
    },
  }),
};

const BottomSheetWithContext = withBottomSheetContext(BottomSheetContent);

const namespace = 'ui-bs-menu';

const BottomSheetMenu = ({
  nodes,
  behavior,
  title,
  repurchase,
  ...props
}) => {
  const { marketplaceInfo: { isWebview }, osName } = useRenderContext();
  const isBottomSheet = behavior?.toLowerCase() === behaviorType.BOTTOM_SHEET;
  const isRedirect = behavior?.toLowerCase() === behaviorType.REDIRECT;
  const isRepurchase = !!repurchase && Object.keys(repurchase).length > 0;

  const sidebarClass = classNames(
    namespace,
    `${namespace}-layout__float`,
    `${namespace}-layout__float-show`,
    `${namespace}-layout__float--full`,
    { 'ui-bs-menu-eshops--ios': isRedirect && isWebview && (!osName || osName?.toLowerCase() === 'ios') },
  );

  const handleWebviewRedirect = (url) => {
    if (isBottomSheet) {
      window.MobileWebKit.executeNative({
        method: 'navigation_bottomsheet',
        args: {
          close: true,
          deeplink: url,
        },
      });
      return;
    }

    window.MobileWebKit.navigation.push(url);
  };

  const handleWebMobileRedirect = (url) => {
    if (isBottomSheet) {
      if (isAllowedOrigin()) {
        window.parent.postMessage({ name: 'bs:redirect', url }, '*');
      }
    } else {
      window.location.href = url;
    }
  };

  const handleRedirect = (url) => {
    if (isWebview) {
      handleWebviewRedirect(url);
    } else {
      handleWebMobileRedirect(url);
    }
  };

  const handleClose = () => {
    if (isWebview) {
      behaviorTypeAction[behavior?.toLowerCase()]?.();
      return;
    }

    if (isBottomSheet) {
      if (isAllowedOrigin()) {
        window.parent.postMessage({ name: 'bs:close' }, '*');
      }
    } else {
      window.history.back();
    }
  };

  const hasTitle = isRedirect || isWebview || isRepurchase;

  let repurchaseData = {};

  if (isRepurchase) {
    repurchaseData = {
      variation: 'bottomsheet',
      title: repurchase?.description?.title,
      subtitle: repurchase?.description?.text,
      repurchase_count: repurchase?.repurchase_count,
      redirectLink: isWebview ? repurchase?.deeplink : repurchase?.link,
      items: repurchase?.items,
    };
  }

  return (
    <div className={sidebarClass}>
      <BottomSheetWithContext
        hasSwiper={false}
        title={hasTitle ? title : null}
        onRedirect={(url) => handleRedirect(url)}
        onClose={handleClose}
        corridors={nodes}
        isWebView={isWebview}
        isRepurchase={isRepurchase}
        repurchaseData={repurchaseData}
        {...props}
      />
    </div>
  );
};

BottomSheetMenu.propTypes = {
  nodes: array,
  title: string,
  behavior: string,
};

export default BottomSheetMenu;
