import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'nordic/image';

import Action from '../action';

const Badge = ({
  label,
  image,
  position,
  link,
  tabIndex = -1,
  mounted,
}) => {
  const { url } = link;

  return (
    <div className="carousel__item" tabIndex={tabIndex}>
      <Action target={url}>
        <div className={`carousel__item__${position}`}>
          <div className="carousel__item-img">
            <Image
              src={image}
              alt={label}
              mounted={mounted}
            />
          </div>
          {label && <h3 className="carousel__item-title">{label}</h3>}
        </div>
      </Action>
    </div>
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  link: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
  tabIndex: PropTypes.number,
  mounted: PropTypes.bool,
};

export default Badge;
