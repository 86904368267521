import React from 'react';

import BottomSheetContext from '../appearance/bottomSheetMenu/context/BottomSheetContext';

const { useContext } = React;

const withBottomSheetContext = (Component) => {
  const ComponentWithBottomSheetContext = ({ is_repurchase: isRepurchaseIframe, isRepurchase, ...props }) => {
    const { setUrl, setIsOpen, isOpen, url } = useContext(BottomSheetContext);
    const isRepurchaseValue = isRepurchaseIframe || isRepurchase;

    const newProps = {
      ...props,
      setUrl,
      setIsOpen,
      isOpen,
      url,
      isRepurchase: isRepurchaseValue,
    };

    return (
      <Component
        {...newProps}
      />
    );
  };

  return ComponentWithBottomSheetContext;
};

export default withBottomSheetContext;
