const React = require('react');

const { useRenderContext } = require('../pages/home/context');

const withAffiliatesMetrics = (WrappedComponent) => {
  const AffiliatesMetrics = (props) => {
    const { marketplaceInfo } = useRenderContext();
    const { trackingId, isWebview } = marketplaceInfo;
    const newProps = {
      ...props,
      trackingId,
      lazyLoad: !isWebview ?? true,
    };
    return (
      <WrappedComponent
        {...newProps}
      />
    );
  };
  return AffiliatesMetrics;
};

module.exports = withAffiliatesMetrics;
