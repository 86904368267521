const React = require('react');
const classNames = require('classnames');
const { useRenderContext } = require('../pages/home/context');

const withFollowerData = (WrappedComponent) => {
  const FollowerData = (props) => {
    const { device_type: deviceType, followers } = props;
    const { marketplaceInfo, device, appearance, osName } = useRenderContext();
    const { buyerId, ownerId, homeURL, isWebview, siteId, d2id, userSiteId } = marketplaceInfo;
    const [barInfo, setBarInfo] = React.useState(props?.bar_info || {});
    const [followersLocal, setFollowersLocal] = React.useState(followers);

    const hasDynamicCarousels = appearance.getComponentByName('DynamicCarousels');
    const hideFollowers = isWebview && userSiteId && userSiteId !== siteId;

    const callAfterFollow = (dataFunction) => {
      const { action } = dataFunction || {};
      const messageEvent = new MessageEvent('reload-coupons', {
        data: { callAction: action },
      });
      if (window?.dispatchEvent) {
        window.dispatchEvent(messageEvent);
      }
    };

    const updateLocalFollowState = ({ data }) => {
      const { followState } = data || {};

      if (followState === 'stop_follow') {
        setBarInfo((prev) => ({
          ...prev,
          visible: false,
        }));

        if (followersLocal) {
          setFollowersLocal((prev) => ({
            ...prev,
            priority_status: 'stop_follow',
          }));
        }
      }
    };

    React.useEffect(() => {
      window.addEventListener('reload-coupons', updateLocalFollowState);
      return () => window.removeEventListener('reload-coupons', updateLocalFollowState);
    }, []);

    const newProps = {
      ...props,
      followers: hideFollowers ? null : followersLocal,
      device_type: deviceType === 'tablet' ? 'mobile' : deviceType,
      ownerId,
      buyerId,
      baseURL: `${homeURL}/api`,
      isWebview,
      siteId,
      d2id,
      callAfterFollow,
      bar_info: barInfo,
    };

    if (hasDynamicCarousels) {
      const withoutFollowerProps = {
        ...newProps,
        followers: false,
      };

      const iosEshopHeaderClass = classNames({
        'eshops-header--ios':
          isWebview && (!osName || osName?.toLowerCase() === 'ios'),
      });

      if (device === 'mobile') {
        return (
          <div className={`remove-shadow-container ${iosEshopHeaderClass}`}>
            <WrappedComponent {...withoutFollowerProps} />
          </div>
        );
      }

      return <WrappedComponent {...withoutFollowerProps} />;
    }

    return <WrappedComponent {...newProps} />;
  };
  return FollowerData;
};

module.exports = withFollowerData;
