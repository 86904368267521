import classnames from 'classnames';

const getSectionClassName = (alignment, variant) => {
  const sectionClassName = classnames('video-and-text', {
    'video-text--words-left': alignment === 'left',
    'video-text--words-center': alignment === 'center',
    'video-text--words-right': alignment === 'right',
    'video-text--video-variant1': variant === 'v1',
    'video-text--video-variant2': variant === 'v2',
    'video-text--video-variant3': variant === 'v3',
  });
  return sectionClassName;
};

const getSectionVideo = (showVideo, variant) => {
  const sectionVideo = classnames('video-and-text__col', {
    'video-and-text__container-text': variant === 'v1',
    'video-and-text__container-text-variant2': variant === 'v2' && !showVideo,
    'video-and-text__container-text-variant3': variant === 'v3',
    'video-and-text__container-text-remove-text': variant === 'v2' && showVideo,
  });
  return sectionVideo;
};

const getShowYoutube = variant => {
  const showYoutube = classnames(
    'video-and-text__image-player-cover', {
      'video-and-text__youtube-player': variant === 'v1' || variant === 'v2',
      'video-and-text__youtube-player-variant3': variant === 'v3',
    },
  );
  return showYoutube;
};

const getShowPlayer = variant => {
  const showPlayer = classnames({
    'video-and-text__image-player': variant === 'v1' || variant === 'v2',
    'video-and-text__image-player-variant3': variant === 'v3',
  });
  return showPlayer;
};

const getVideoAndTextClassNames = ({ variant, showVideo, alignment }) => ({
  sectionClassName: getSectionClassName(alignment, variant),
  sectionVideo: getSectionVideo(showVideo, variant),
  showYoutube: getShowYoutube(variant),
  showPlayer: getShowPlayer(variant),
});

export default getVideoAndTextClassNames;
