import React, { useContext, useEffect, useRef } from 'react';
import classnames from 'classnames';

import BottomSheetContext from '../appearance/bottomSheetMenu/context/BottomSheetContext';
import { useRenderContext } from '../pages/home/context';

const BEHAVIOR_TYPE = {
  REDIRECT: 'redirect',
  BOTTOM_SHEET: 'bottom_sheet',
};

const namespace = 'feature-corridors';

const withFeaturedCorridors = (Component) => {
  const FeaturedCorridors = (props) => {
    const { marketplaceInfo: { isWebview }, appearance } = useRenderContext();
    const { setIsOpen, setUrl } = useContext(BottomSheetContext);
    const hasCalledNativeBottomSheet = useRef(false);

    const hasRepurchase = appearance.getComponentByName('Repurchase');
    const wrapperClasss = classnames(
      { [`${namespace}--grid-mode`]: hasRepurchase },
    );

    const openBottomSheet = async (url) => {
      if (!hasCalledNativeBottomSheet.current) {
        await window.MobileWebKit.navigation.push(url);
        hasCalledNativeBottomSheet.current = true;
      }
    };

    useEffect(() => {
      if (window.MobileWebKit && window.MobileWebKit.platform.toLowerCase() === 'android') {
        window.MobileWebKit.lifecycle.onViewAppeared(() => {
          hasCalledNativeBottomSheet.current = false;
        });
      }
    }, []);

    const handleAction = ({ url, behavior }) => {
      const isBottomSheet = behavior?.toLowerCase() === BEHAVIOR_TYPE.BOTTOM_SHEET;
      if (isWebview) {
        if (isBottomSheet) {
          const osName = window.MobileWebKit.platform;
          if (osName === 'android') {
            openBottomSheet(url);
          } else {
            const args = {
              method: 'wk_present',
              args: {
                deeplink: url,
                animated: true,
                over_context: false,
              },
            };
            window.MobileWebKit.executeNative(args);
          }
        } else {
          window.MobileWebKit.navigation.push(url)
            .catch(() => {
              window.location = url;
            });
        }
      } else if (isBottomSheet) {
        setIsOpen(true);
        setUrl(url);
      } else {
        window.location = url;
      }
    };

    return (
      <div className={wrapperClasss}>
        <Component
          {...props}
          onAction={handleAction}
        />
      </div>
    );
  };
  return FeaturedCorridors;
};

export default withFeaturedCorridors;
