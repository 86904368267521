const setWebkitTitle = (title) => {
  if (window?.MobileWebKit && window?.MobileWebKit?.platform === 'android') {
    window.MobileWebKit.topbar.setup({
      bar_title: title,
    });
  }
};

module.exports = {
  setWebkitTitle,
};
