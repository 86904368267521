const React = require('react');

const withAffiliateTitle = (WrappedComponent) => {
  const FollowerData = (props) => {
    const { title } = props;
    const newProps = {
      ...props,
      title: '',
    };

    return (
      <>
        <div className="ui-relevant-item ui-relevant-item--row affiliate-title">
          <h2>{title}</h2>
        </div>
        <div className="affiliate-card">
          <WrappedComponent {...newProps} />
        </div>
      </>
    );
  };

  return FollowerData;
};

module.exports = withAffiliateTitle;
