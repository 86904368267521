import React from 'react';

import { MenuTab } from 'eshops-components-library';

import { useRenderContext } from '../../pages/home/context';

const MenuTabWrapper = (props) => {
  const { device } = useRenderContext();

  return (
    <MenuTab
      deviceType={device}
      {...props}
    />
  );
};

export default MenuTabWrapper;
