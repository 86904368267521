const imageHost = 'https://http2.mlstatic.com/';

const buildImageUrl = (image) => {
  const isImageUrl = image.match(/^(http:\/\/|https:\/\/)/);
  if (isImageUrl) {
    return image;
  }

  return `${imageHost}D_NQ_${image}-OO.jpg`;
};

const MAX_ITEMS_PER_VIEW_BY_DEVICE = {
  desktop: 7,
  mobile: 3,
};

const SLIDE_THRESHOLD_BY_DEVICE = {
  desktop: 0,
  mobile: 40,
};

export {
  buildImageUrl,
  MAX_ITEMS_PER_VIEW_BY_DEVICE,
  SLIDE_THRESHOLD_BY_DEVICE,
};
