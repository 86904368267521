const React = require('react');

const { useEffect } = React;

const { ZIP_CODE_CP_EVENT } = require('../../utils/constants');

const ZipcodeSubscription = ({ storefrontType }) => {
  const forceRefresh = () => {
    window.MobileWebKit.navigation.reload();
  };

  /**
   * Force refresh of the page to update the zip code in the top bar in Android
   * With the Webkit migration, the top bar is not responding to the update of the zip code
   * Remove this function when the top bar is fixed
   */
  const forceZipCodeRefresh = () => {
    window.MobileWebKit.events.dispatch('page_for_result_topic', { result_code: -1 });
  };

  function handleChangeZipCode() {
    forceZipCodeRefresh();
    forceRefresh();
  }

  useEffect(() => {
    if (!window.MobileWebKit || !storefrontType.startsWith('super')) return null;

    const cpSubscriptionEvent = window.MobileWebKit.events.subscribe(ZIP_CODE_CP_EVENT, handleChangeZipCode);

    return () => {
      window.MobileWebKit.events.unsubscribe(cpSubscriptionEvent);
    };
  }, []);

  return null;
};

module.exports = ZipcodeSubscription;
