/* eslint-disable max-len */
export const regularExpressions = {
  IS_VIDEO_YOUTUBE: '^(?:https?:\\/\\/)?(?:m\\.|www\\.)?(?:youtu\\.be\\/|youtube\\.com\\/(?:embed\\/|v\\/|watch\\?v=|watch\\?.+&v=))((\\w|-){11})(?:\\S+)?$',
  IS_DAILYMOTION: '^(?:https?:\\/\\/)?(?:m\\.|www\\.)?(?:dailymotion\\.com\\/embed\\/video\\/|dai\\.ly\\/|dai\\.ly\\/embed\\/video\\/)([a-zA-Z0-9]+)(?:\\S+)?$',
  IS_VIMEO: '^(?:https?:\\/\\/)?(?:m\\.|player\\.)?(?:vimeo\\.com\\/video\\/)(\\d+)(?:\\S+)?$',
  IS_TWITCH: '^(?:https?:\\/\\/)?(?:m\\.|player\\.)?(?:twitch\\.tv\\/|player\\.twitch\\.tv\\/?video=)(\\S+)(?:\\S+)?$',
  IS_URL_GENERAL: '/^(https?:\\/\\/)?(www\\.)?(youtube\\.com|youtu\\.?be|vimeo\\.com|dailymotion\\.com|twitch\\.tv)\b([-a-zA-Z0-9()@:%_.~#?&//=]*)$/i',
};

export const MODIFIERS = {
  DEFAULT: 'default',
  ERROR: 'error',
};

export const EXPLORER_THEME = 'explorer';
