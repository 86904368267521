const React = require('react');
const { useRenderContext } = require('../pages/home/context');
const { ESHOP, SUPER } = require('../utils/constants/sections');

function withMarketplaceInfo(WrappedComponent) {
  const MarketplaceInfo = (props) => {
    const { marketplaceInfo: { shopType } } = useRenderContext();
    return (
      <WrappedComponent
        {...props}
        layoutTheme={ESHOP}
        isSuper={shopType?.includes(SUPER)}
      />
    );
  };
  return MarketplaceInfo;
}

module.exports = withMarketplaceInfo;
