import React from 'react';
import VideoPlayer from '../../components/VideoPlayer';
import { useRenderContext } from '../../pages/home/context';

const VideoPlayerComponent = (props) => {
  const { isEshops } = useRenderContext();

  return <VideoPlayer {...props} isEshops={isEshops} />;
};

export default VideoPlayerComponent;
