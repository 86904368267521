const React = require('react');
const { string } = require('prop-types');

const withLayoutWrapper = (WrappedComponent) => {
  const LayoutWrapper = (props) => (
    <WrappedComponent
      wrapperStyle={props?.styles}
      {...props}
    />
  );

  LayoutWrapper.propTypes = {
    display: string,
    gap: string,
    justifyContent: string,
    alignItems: string,
    flexDirection: string,
    padding: string,
    margin: string,
  };

  return LayoutWrapper;
};

module.exports = withLayoutWrapper;
