import React from 'react';

import BadgeCarousel from '../../components/badge-carousel';
import { useRenderContext } from '../../pages/home/context';

const BadgeCarouselContainer = (props) => {
  const { device } = useRenderContext();

  return <BadgeCarousel {...props} deviceType={device} />;
};

export default BadgeCarouselContainer;
