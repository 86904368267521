const React = require('react');

const { useRenderContext } = require('../pages/home/context');

const withURLQueryParams = (WrappedComponent) => {
  const RelevantItemData = (props) => {
    const { marketplaceInfo } = useRenderContext();
    const { isWebview, d2id } = marketplaceInfo;

    const newProps = {
      ...props,
      isWebview,
      d2id,
    };
    return (
      <WrappedComponent
        {...newProps}
      />
    );
  };
  return RelevantItemData;
};

module.exports = withURLQueryParams;
