const updateCartFreya = (isWebview) => {
  if (isWebview && window?.MobileWebKit) {
    window.MobileWebKit.executeNative({
      method: 'refresh_cart',
      args: {},
    });
    return;
  }
  if (window?.freya) {
    window.freya.emit('cart:refresh');
  }
};

export default updateCartFreya;
