import React from 'react';

import MarketPlaceView from '../../pages/marketplaceHome/components/marketplaceView';

const View = props => (<MarketPlaceView {...props} />);

export { getServerSideProps, setPageSettings } from '../../pages/marketplaceHome/controller';

export const hydrate = 'hydrateRoot';

export default View;
