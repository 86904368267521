import React from 'react';

import RecommendationCarousel from '@mshops-components-library/recommendations-carousel';

import { useRenderContext } from '../../pages/home/context';
import { WITH_POLYCARD_RECOS } from '../../utils/constants/shop/types';
import PolycardCarousel from '../polycardCarousel';

const RecommendationCarouselWrapper = (props) => {
  const { device, shopType, siteId } = useRenderContext();
  const { trackingId, lazyLoad, recommendations } = props;
  const { recommendation_info: { polycard_context: polycardContext, polycards } } = recommendations;

  const setTrackingId = (data) => {
    const recomendationWithTrackId = data?.recommendation_info?.recommendations.map(recommendation => {
      const url = new URL(recommendation.url);
      const decodedUrl = decodeURIComponent(url.hash);
      const newUrl = decodedUrl.replace('tracking_id={tracking_id}', `tracking_id=${trackingId}`);
      url.hash = newUrl.substring(1);
      return {
        ...recommendation,
        url: url.toString(),
      };
    });

    return {
      ...data,
      recommendation_info: {
        ...data.recommendation_info,
        recommendations: recomendationWithTrackId,
      },
    };
  };

  const isPolycard = polycards?.length > 0;
  const isPolycardCarousel = polycardContext && WITH_POLYCARD_RECOS.includes(shopType) && isPolycard;

  if (isPolycardCarousel) {
    return (
      <div className="recommendation-carrousel-wrapper ui-ms-polycard-carousel--recos">
        <PolycardCarousel
          device={device}
          polycardContext={polycardContext}
          polycards={polycards}
          shopType={shopType}
          siteId={siteId}
          trackingId={trackingId}
        />
      </div>
    );
  }

  const recommendation = setTrackingId(recommendations);

  return (
    <>
      <div className="recommendation-carrousel-wrapper">
        <RecommendationCarousel deviceType={device} lazyLoad={lazyLoad} {...props} recommendations={recommendation} />
      </div>
    </>
  );
};

export default RecommendationCarouselWrapper;
