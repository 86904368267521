import React from 'react';
import PropTypes from 'prop-types';
import PlayIconButton from '@mshops-web-components/icons/PlayIconButton';

const PlayIconVideo = ({ onImageClick, i18n = { gettext: f => f } }) => {
  const onInternalImageClick = () => {
    onImageClick();
  };

  const buttonLabel = i18n.gettext('Ver video');

  return <PlayIconButton buttonLabel={buttonLabel} className="play-button" onClick={onInternalImageClick} />;
};

PlayIconVideo.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }).isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default PlayIconVideo;
