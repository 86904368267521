/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-undef */
import React from 'react';
import { string, shape, node, array } from 'prop-types';
import classNames from 'classnames';
import CategoryTab from '../categoryTab';
import { useRenderContext } from '../../pages/home/context';

const { Children, useState, cloneElement, useEffect, useMemo, useRef } = React;


// TODO Added component to mshops-lib or eshop-lib

const DynamicLayout = (props) => {
  const { marketplaceInfo: { isWebview }, osName } = useRenderContext();
  const { children, tabs, deviceType, category_title: categoryTitle } = props;
  const [selectedTab, setSelectedTab] = useState('');
  const [blockScroll, setBlockScroll] = useState(false);
  const carouselsRefs = useRef([]);
  const iosMenuTabsClass = classNames(
    { 'eshops-menu-tabs-ios': isWebview && (!osName || osName?.toLowerCase() === 'ios') },
  );

  const iosDynamicCarouselClass = classNames(
    { 'eshops-dynamic-carousel-ios': isWebview && (!osName || osName?.toLowerCase() === 'ios') },
  );

  const findSelectedTab = (tabId) => {
    const selected = tabs.findIndex(tab => tab.id === tabId);
    return selected;
  };

  /* istanbul ignore next */
  const handleClick = (tabId) => {
    const selected = findSelectedTab(tabId);
    setSelectedTab(selected);
    setBlockScroll(true);
    const section = document.getElementById(tabId);
    if (!section) {
      return;
    }
    const padding = (selected === tabs?.length - 2) ? 220 : 155;
    const scrollPosition = section.offsetTop - padding;
    window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
    setTimeout(() => {
      setBlockScroll(false);
    }, 700);
  };

  /* istanbul ignore next */
  const handleScroll = () => {
    if (deviceType !== 'mobile' || blockScroll) return;

    const topLimit = 130;
    const bottomLimit = 180;
    const beforeLastElementTopLimit = 200;
    const beforeLastElementBottomLimit = 280;
    const lastElementTopLimit = 430;
    const lastElementBottomLimit = 510;
    const elements = carouselsRefs?.current;

    elements.forEach((element, index) => {
      const rect = element.getBoundingClientRect();
      const elementTop = rect.top;
      if ((index === elements.length - 1 && elementTop >= lastElementTopLimit && elementTop < lastElementBottomLimit)
        || (index === elements.length - 2 && elementTop >= beforeLastElementTopLimit && elementTop < beforeLastElementBottomLimit)) {
        setSelectedTab(index);
      } else if (elementTop >= topLimit && elementTop < bottomLimit) {
        const selected = findSelectedTab(element.id);
        setSelectedTab(selected);
        if (selected === 0) {
          const tabToDisplay = document.querySelector('.andes-tab');
          const container = tabToDisplay?.parentElement?.parentElement;
          container.scrollLeft = tabToDisplay.offsetLeft;
        }
      }
    });
  };

  const renderChildren = useMemo(() => Children.map(children, (dynamicCarousel, index) => (
    <div
      key={dynamicCarousel.props?.node?.id}
      id={dynamicCarousel.props?.node?.id}
      ref={(ref) => (carouselsRefs.current[index] = ref)}
      className="dynamic-carousel"
    >
      {cloneElement(dynamicCarousel, { useChevron: false })}
    </div>
  )), [children]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [blockScroll]);

  return (
    <>
      <div className={`tabs-menu-container ${iosMenuTabsClass}`}>
        {deviceType === 'mobile' && <CategoryTab
          tabs={tabs}
          selectedTab={selectedTab || 0}
          onClick={handleClick}
        />}
      </div>
      {categoryTitle && <h2 className="category-title">{categoryTitle}</h2>}
      <section className={`tab-menu-carousels ${iosDynamicCarouselClass}`}>
        {renderChildren}
      </section>
    </>
  );
};

DynamicLayout.protoTypes = {
  props: shape({
    categoryTitle: string,
    children: node,
    deviceType: string,
    tabs: array,
  }).isRequired,
};

export default DynamicLayout;
