import React from 'react';
import PropTypes from 'prop-types';
import PlayIcon from '@mshops-web-components/icons/PlayIcon';

const PlayIconVideo = ({ onImageClick }) => {
  const onInternalImageClick = () => {
    onImageClick();
  };

  return <PlayIcon className="play-icon" onClick={onInternalImageClick} />;
};

PlayIconVideo.propTypes = {
  onImageClick: PropTypes.func.isRequired,
};

export default PlayIconVideo;
