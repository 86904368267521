const React = require('react');
const { useRenderContext } = require('../pages/home/context');

const withHandleMenu = (WrappedComponent) => {
  const HandleMenu = (props) => {
    const { marketplaceInfo, osName } = useRenderContext();
    const { isWebview, labelURL } = marketplaceInfo;

    const handleMenu = () => {
      window.location.href = labelURL;
    };

    const newProps = {
      ...props,
      osName,
      isWebview,
      handleMenu,
    };

    return (
      <WrappedComponent
        {...newProps}
      />
    );
  };
  return HandleMenu;
};

module.exports = withHandleMenu;
