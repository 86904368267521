import React from 'react';

const BottomSheetContext = React.createContext({
  url: '',
  isOpen: false,
  setIsOpen: (value) => value,
  setUrl: (value) => value,
});

export default BottomSheetContext;
